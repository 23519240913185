<template>
  <b-overlay
    variant="white"
    :show="showOverlay"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <sidebar-template title="Create New Question" :closeAction="closeAction">
      <template #content>
        <question-form
          :tprQuestionnaireId="tprQuestionnaireId"
          :handleSubmitClick="handleSubmitClick"
        />
      </template>
    </sidebar-template>
  </b-overlay>
</template>

<script>
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import QuestionForm from "./QuestionForm.vue";
import { BOverlay } from "bootstrap-vue";

export default {
  mixins: [ResponseMixins],
  components: { SidebarTemplate, QuestionForm, BOverlay },
  data() {
    return {
      showOverlay: false,
    };
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    tprQuestionnaireId: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleSubmitClick(formData) {
      console.log(formData);
      this.createNewQuestion(formData);
    },

    createNewQuestion(data) {
      this.showOverlay = true;
      const bodyFormData = new FormData();
      if (data.policies.length > 0) {
        data.policies.forEach((p, index) => {
          bodyFormData.append(`policy_ids[${index}]`, p);
        });
      }
      bodyFormData.append("tpr_questionnaire_id", this.tprQuestionnaireId);
      //   const newData = {
      //     tpr_questionnaire_id: this.tprQuestionnaireId,
      //   };

      Object.keys(data).forEach((key) => {
        if (data[key] && data[key] !== null) {
          if (key == "riskValuesNo") {
            // newData["risk_values[no]"] = data[key];
            bodyFormData.append("risk_values[no]", data[key]);
          } else if (key == "riskValuesYes") {
            // newData["risk_values[yes]"] = data[key];
            bodyFormData.append("risk_values[yes]", data[key]);
          } else if (key == "riskValuesNotApplicable") {
            // newData["risk_values[not_applicable]"] = data[key];
            bodyFormData.append("risk_values[not_applicable]", data[key]);
          } else if (key == "policies") {
          } else {
            // newData[key] = data[key];
            bodyFormData.append(key, data[key]);
          }
        }
      });

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questions`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.closeAction();
          this.reloadParent();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>
