<template>
  <b-overlay
    variant="white"
    :show="showOverlay"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <sidebar-template title="Update Question" :closeAction="closeAction">
      <template #content>
        <question-form
          v-if="initialData !== null"
          :tprQuestionnaireId="tprQuestionnaireId"
          :handleSubmitClick="handleSubmitClick"
          :initialData="initialData"
        />
      </template>
    </sidebar-template>
  </b-overlay>
</template>

<script>
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import QuestionForm from "./QuestionForm.vue";
import { BOverlay } from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import PolicyMixins from "../../mixins/PolicyMixins";
export default {
  mixins: [ResponseMixins, PolicyMixins],
  components: { SidebarTemplate, QuestionForm, BOverlay },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    tprQuestionnaireId: { type: String, required: true },
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialData: null,
      showOverlay: false,
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getQuestionData();
    },

    handleSubmitClick(data) {
      this.showOverlay = true;
      const bodyFormData = new FormData();

      if (data.policies.length > 0) {
        data.policies.forEach((p, index) => {
          bodyFormData.append(`policy_ids[${index}]`, p);
        });
      }
      bodyFormData.append("tpr_questionnaire_id", this.tprQuestionnaireId);
      bodyFormData.append("tpr_question_id", this.questionId);
      bodyFormData.append("_method", "PUT");

      Object.keys(data).forEach((key) => {
        if (data[key] && data[key] !== null) {
          if (key == "riskValuesNo") {
            bodyFormData.append("risk_values[no]", data[key]);
          } else if (key == "riskValuesYes") {
            bodyFormData.append("risk_values[yes]", data[key]);
          } else if (key == "riskValuesNotApplicable") {
            bodyFormData.append("risk_values[not_applicable]", data[key]);
          } else if (key == "policies") {
          } else {
            bodyFormData.append(key, data[key]);
          }
        }
      });
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questions/${this.questionId}`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((res) => {
          this.handleResponse(res);
          this.closeAction();
          this.reloadParent();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getQuestionData() {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questions/${this.questionId}`,
      };
      this.$http(options)
        .then((res) => {
          const data = res.data.data.question;
          const newFormData = {
            title: data.title || null,
            description: data.description || null,
            policies: data.policy_ids || [],
            response_mandatory: data.response_mandatory || null,
            evidence_rqd: data.evidence_rqd || false,
            is_justification_mandatory:data.is_justification_mandatory||false,
            question_response: data.question_response || null,
            tpr_question_id: data._id || null,
            riskValuesNo:
              typeof data.risk_values.no == "undefined"
                ? null
                : data.risk_values.no,
            riskValuesYes:
              typeof data.risk_values.yes == "undefined"
                ? null
                : data.risk_values.yes,
            riskValuesNotApplicable:
              typeof data.risk_values.not_applicable == "undefined"
                ? null
                : data.risk_values.not_applicable,
            answer: typeof data.answer == "undefined" ? null : data.answer,
            response_type: data.response_type || null,
            any_condition: data.any_condition || false,
          };
          this.initialData = newFormData;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>