var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"question_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick(_vm.formData)}}},[_c('b-form-group',{attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"vid":"title","name":"Title","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"Title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","debounce":"500","rows":"3","max-rows":"5","state":errors.length > 0 ? false : null,"name":"Description"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Policies"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Policies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"Search & Select","multiple":"","reduce":function (opt) { return opt.value; },"options":_vm.policyOptions,"state":errors.length > 0 ? false : null},on:{"search":function (searchQuery) {
              _vm.getPoliciesAndSetOptions({ search: searchQuery });
            }},model:{value:(_vm.formData.policies),callback:function ($$v) {_vm.$set(_vm.formData, "policies", $$v)},expression:"formData.policies"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Answer"}},[_c('validation-provider',{attrs:{"name":"Answer","rules":"required","vid":"answer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.response_type_answer_options,"placeholder":"Select.."},model:{value:(_vm.formData.answer),callback:function ($$v) {_vm.$set(_vm.formData, "answer", $$v)},expression:"formData.answer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Response Mandatory","vid":"response_mandatory"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.formData.response_mandatory),callback:function ($$v) {_vm.$set(_vm.formData, "response_mandatory", $$v)},expression:"formData.response_mandatory"}},[_vm._v(" Response Mandatory? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.formData.response_mandatory == '1')?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Condition Validation","vid":"any_condition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.formData.any_condition),callback:function ($$v) {_vm.$set(_vm.formData, "any_condition", $$v)},expression:"formData.any_condition"}},[_vm._v(" Condition Validation? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.formData.any_condition == '1')?_c('b-form-group',{attrs:{"label":"Question"}},[_c('validation-provider',{attrs:{"name":"Question","vid":"tpr_question_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.questionOptions,"placeholder":"Select.."},model:{value:(_vm.formData.tpr_question_id),callback:function ($$v) {_vm.$set(_vm.formData, "tpr_question_id", $$v)},expression:"formData.tpr_question_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.formData.any_condition)?_c('b-form-group',{attrs:{"label":"Response"}},[_c('validation-provider',{attrs:{"name":"Response","vid":"question_response"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.response_type_answer_options,"placeholder":"Select.."},model:{value:(_vm.formData.question_response),callback:function ($$v) {_vm.$set(_vm.formData, "question_response", $$v)},expression:"formData.question_response"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Evidence Required","vid":"evidence_rqd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.formData.evidence_rqd),callback:function ($$v) {_vm.$set(_vm.formData, "evidence_rqd", $$v)},expression:"formData.evidence_rqd"}},[_vm._v(" Evidence Required? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"is_justification_mandatory","vid":"is_justification_mandatory"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.formData.is_justification_mandatory),callback:function ($$v) {_vm.$set(_vm.formData, "is_justification_mandatory", $$v)},expression:"formData.is_justification_mandatory"}},[_vm._v(" Justification Mandatory? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Risk Values"}},[_c('b-form-group',{attrs:{"label":"Yes"}},[_c('validation-provider',{attrs:{"name":"Yes","rules":"required","vid":"risk_values[yes]"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.risk_value_options,"placeholder":"Select.."},model:{value:(_vm.formData.riskValuesYes),callback:function ($$v) {_vm.$set(_vm.formData, "riskValuesYes", $$v)},expression:"formData.riskValuesYes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"No"}},[_c('validation-provider',{attrs:{"name":"No","rules":"required","vid":"risk_values[no]"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.risk_value_options,"placeholder":"Select.."},model:{value:(_vm.formData.riskValuesNo),callback:function ($$v) {_vm.$set(_vm.formData, "riskValuesNo", $$v)},expression:"formData.riskValuesNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Not Applicable"}},[_c('validation-provider',{attrs:{"name":"Not Applicable","rules":"required","vid":"risk_values[not_applicable]"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.risk_value_options,"placeholder":"Select.."},model:{value:(_vm.formData.riskValuesNotApplicable),callback:function ($$v) {_vm.$set(_vm.formData, "riskValuesNotApplicable", $$v)},expression:"formData.riskValuesNotApplicable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Submit")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }