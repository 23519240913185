export default {
  methods: {
    getPolicies(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_ONECSF_API_URL}/policies`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getPolicy(id) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_ONECSF_API_URL}/policies/${id}`,
          };
          this.$http(options)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      },
  

    addPolicy(data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_ONECSF_API_URL}/policies`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updatePolicy(id, data) {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
            if (data[key] && data[key] != null) {
              formData.append(key, data[key]);
            }
          });
  
          const options = {
            method: "POST",
            url: `${process.env.VUE_APP_ONECSF_API_URL}/policies/${id}`,
            data: formData,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },

      deletePolicy(id) {
        return new Promise((resolve, reject) => {
       
          const options = {
            method: "DELETE",
            url: `${process.env.VUE_APP_ONECSF_API_URL}/policies/${id}`,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
  },
  
};
