<template>
  <validation-observer ref="question_form" #default="{ invalid }">
    <b-form
      class="auth-login-form"
      @submit.prevent="handleSubmitClick(formData)"
    >
      <b-form-group label="Title">
        <validation-provider
          vid="title"
          #default="{ errors }"
          name="Title"
          rules="required|min:3"
        >
          <b-form-input
            id="title"
            v-model="formData.title"
            :state="errors.length > 0 ? false : null"
            name="Title"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Description">
        <validation-provider
          #default="{ errors }"
          name="Description"
          vid="description"
        >
          <b-form-textarea
            id="description"
            v-model="formData.description"
            debounce="500"
            rows="3"
            max-rows="5"
            :state="errors.length > 0 ? false : null"
            name="Description"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Policies">
        <validation-provider
          vid="description"
          #default="{ errors }"
          name="Policies"
        >
          <v-select
            v-model="formData.policies"
            label="name"
            placeholder="Search & Select"
            multiple
            :reduce="(opt) => opt.value"
            :options="policyOptions"
            @search="
              (searchQuery) => {
                getPoliciesAndSetOptions({ search: searchQuery });
              }
            "
            :state="errors.length > 0 ? false : null"
          ></v-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- <b-form-group label="Response Type">
        <validation-provider
          #default="{ errors }"
          vid="response_type"
          name="Response Type"
          rules="required"
        >
          <b-form-select
            v-model="formData.response_type"
            :options="response_type_options"
            placeholder="Select.."
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group> -->

      <b-form-group label="Answer">
        <validation-provider
          #default="{ errors }"
          name="Answer"
          rules="required"
          vid="answer"
        >
          <b-form-select
            v-model="formData.answer"
            :options="response_type_answer_options"
            placeholder="Select.."
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Response Mandatory"
          vid="response_mandatory"
        >
          <b-form-checkbox
            v-model="formData.response_mandatory"
            class="custom-control-primary"
            value="1"
            unchecked-value="0"
          >
            Response Mandatory?
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group v-if="formData.response_mandatory == '1'">
        <validation-provider
          #default="{ errors }"
          name="Condition Validation"
          vid="any_condition"
        >
          <b-form-checkbox
            v-model="formData.any_condition"
            class="custom-control-primary"
            value="1"
            unchecked-value="0"
          >
            Condition Validation?
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Question" v-if="formData.any_condition == '1'">
        <validation-provider
          #default="{ errors }"
          name="Question"
          vid="tpr_question_id"
        >
          <b-form-select
            v-model="formData.tpr_question_id"
            :options="questionOptions"
            placeholder="Select.."
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Response" v-if="formData.any_condition">
        <validation-provider
          #default="{ errors }"
          name="Response"
          vid="question_response"
        >
          <b-form-select
            v-model="formData.question_response"
            :options="response_type_answer_options"
            placeholder="Select.."
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Evidence Required"
          vid="evidence_rqd"
        >
          <b-form-checkbox
            v-model="formData.evidence_rqd"
            class="custom-control-primary"
            value="1"
            unchecked-value="0"
          >
            Evidence Required?
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="is_justification_mandatory"
          vid="is_justification_mandatory"
        >
          <b-form-checkbox
            v-model="formData.is_justification_mandatory"
            class="custom-control-primary"
            value="1"
            unchecked-value="0"
          >
          Justification Mandatory?
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Risk Values">
        <b-form-group label="Yes">
          <validation-provider
            #default="{ errors }"
            name="Yes"
            rules="required"
            vid="risk_values[yes]"
          >
            <b-form-select
              v-model="formData.riskValuesYes"
              :options="risk_value_options"
              placeholder="Select.."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- :rules="{
              required:
                !formData.riskValuesYes && !formData.riskValuesNotApplicable,
            }" -->

        <b-form-group label="No">
          <validation-provider
            #default="{ errors }"
            name="No"
            rules="required"
            vid="risk_values[no]"
          >
            <b-form-select
              v-model="formData.riskValuesNo"
              :options="risk_value_options"
              placeholder="Select.."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Not Applicable">
          <validation-provider
            #default="{ errors }"
            name="Not Applicable"
            rules="required"
            vid="risk_values[not_applicable]"
          >
            <b-form-select
              v-model="formData.riskValuesNotApplicable"
              :options="risk_value_options"
              placeholder="Select.."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form-group>

      <b-button type="submit" variant="success" :disabled="invalid"
        ><feather-icon icon="CheckIcon" class="mr-25" />
        <span class="align-middle">Submit</span></b-button
      >
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import ResponseMixins from "../../mixins/ResponseMixins";
import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";
import PolicyMixins from "../../mixins/PolicyMixins";
import vSelect from "vue-select";

export default {
  mixins: [ResponseMixins, PolicyMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    required,
    FeatherIcon,
    vSelect,
  },

  props: {
    tprQuestionnaireId: {
      type: String,
      required: true,
    },
    handleSubmitClick: {
      type: Function,
      required: true,
    },

    initialData: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      // response_type_options: [
      //   { text: "Select", value: null },
      //   // { text: "Text Box", value: 1 },
      //   { text: "Select Box", value: 2 },
      // ],
      response_type_answer_options: [
        { text: "Select", value: null },
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      risk_value_options: [
        { text: "Select", value: null },
        { text: "Low", value: 1 },
        { text: "Medium", value: 2 },
        { text: "High", value: 3 },
      ],
      questions: [],
      policyOptions:[],
      formData: {
        title: null,
        description: null,
        policies:[],
        response_mandatory: false,
        evidence_rqd: false,
        question_response: null,
        tpr_question_id: null,
        riskValuesNo: null,
        riskValuesYes: null,
        riskValuesNotApplicable: null,
        answer: null,
        response_type: null,
        any_condition: false,
      },
    };
  },

  computed: {
    questionOptions() {
      return this.questions.map((quest) => {
        return {
          text: quest.title,
          value: quest._id,
        };
      });
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      if (this.initialData && this.initialData !== null) {
        this.formData = this.initialData;
      }
      this.getQuestionsAndSetData();
      this.getPoliciesAndSetOptions({ page: 1 });
    },

    getPoliciesAndSetOptions(params) {
      this.getPolicies(params)
        .then((res) => {
          this.policyOptions = res.data.data.data.map((p) => {
            return { name: p.title, value: p._id };
          });
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    getQuestionsAndSetData() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questions/list/${this.tprQuestionnaireId}`,
      };

      this.$http(options)
        .then((res) => {
          this.questions = res.data.data.questions;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          if (err.response.status === 422) {
            this.$refs.question_form.setErrors(err.response.data.errors);
          }
        })
        .finally(() => {});
    },

    resetFormData() {
      this.formData = {
        title: null,
        description: null,
        policies:[],
        tpr_questionnaire_id: null,
        response_mandatory: false,
        evidence_rqd: false,
        question_response: null,
        is_justification_mandatory:false,
        tpr_question_id: null,
        riskValuesNo: null,
        riskValuesYes: null,
        risk_valuesNotApplicable: null,
        answer: null,
        response_type: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>