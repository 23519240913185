<template>
  <div>
    <b-sidebar
      id="sidebar-question-add"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
    >
      <question-create-sidebar
        :closeAction="closeQuestionAddSidebar"
        :tprQuestionnaireId="qstnnaireId"
        :reloadParent="load"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-question-update"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
    >
      <question-update-sidebar
        :closeAction="closeQuestionUpdateSidebar"
        :tprQuestionnaireId="qstnnaireId"
        :questionId="selectedQuestionId"
        :reloadParent="load"
      />
    </b-sidebar>

    <b-sidebar
      id="question-detail-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
    >
      <sidebar-template
        title="Question Details"
        :closeAction="hideQuestionDetailsSidebar"
      >
        <template #content>
          <question-details-view
            :questionId="selectedQuestionId"
            :questionnaireId="questionnaire._id"
          />
        </template>
      </sidebar-template>
    </b-sidebar>

    <b-modal ref="modal-import" title="Upload Questions" hide-footer>
      <div class="d-flex flex-column align-items-start">
        <b-form-file
          v-model="selectedFile"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          class="mb-25"
          accept=".csv"
        />

        <div class="d-flex align-items-center justify-content-start mt-1">
          <b-button
            size="sm"
            variant="success"
            @click="handleFileUploadSubmit"
            :disabled="showOverlay || !selectedFile || selectedFile === null"
            ><feather-icon icon="CheckIcon" size="15" class="mr-25" />
            <span class="align-middle">Submit</span></b-button
          >

          <b-button
            class="ml-50"
            size="sm"
            variant="primary"
            @click="handleDownloadButtonClick"
            ><feather-icon icon="DownloadIcon" class="mr-25" /><span
              class="align-middle"
              >Download Sample File</span
            ></b-button
          >
        </div>
      </div>
    </b-modal>
    <div class="card">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              class="mr-1"
              size="sm"
              variant="success"
              @click="handleImportClick"
              ><feather-icon icon="UploadIcon" size="15" class="mr-25" />
              <span class="align-middle">Upload</span></b-button
            >
            <b-button
              class="mr-1"
              size="sm"
              variant="success"
              @click="
                () => {
                  this.$router.push(
                    `/questions-uploads?questionnaireId=${qstnnaireId}`
                  );
                }
              "
              ><feather-icon icon="BookIcon" size="15" class="mr-25" />
              <span class="align-middle">View Upload History</span></b-button
            >
            <span
              v-if="qstionnardetails.is_published == 1"
              class="text-success float-right mr-1"
            >
              <button
                class="btn btn-primary btn-sm float-right mr-1"
                @click="publishModal"
                v-if="qstionnardetails.republish == 1"
                :disabled="
                  !this.$store.state.auth.permissions.includes(
                    'tpr_questionnaire-publish'
                  )
                "
              >
                Republish
              </button>
              <span v-else
                ><feather-icon icon="CheckCircleIcon" />Published</span
              >
            </span>
            <button
              class="btn btn-primary btn-sm float-right mr-1"
              @click="publishModal"
              v-else
              :disabled="
                !this.$store.state.auth.permissions.includes(
                  'tpr_questionnaire-publish'
                )
              "
            >
              Publish
            </button>

            <button
              class="btn btn-primary btn-sm float-right mb-1 mr-1 mt-1"
              @click="openQuestionAddSidebar"
              :disabled="
                !this.$store.state.auth.permissions.includes(
                  'tpr_question-create'
                )
              "
            >
              + Add Question
            </button>
            <!-- <button
              class="btn btn-primary btn-sm float-right mb-1 mr-1 mt-1"
              @click="openQuestion"
              :disabled="
                !this.$store.state.auth.permissions.includes(
                  'tpr_question-create'
                )
              "
            >
              + Add Question
            </button> -->
          </div>
        </div>
      </div>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="row">
          <div class="col-12">
            <h3 class="ml-1">{{ qstionnardetails.title }}</h3>
            <p class="ml-1">{{ qstionnardetails.description }}</p>
          </div>
          <div class="col-12">
            <draggable
              v-model="list"
              tag="ul"
              class="list-group list-group-flush cursor-move p-1"
              @end="newIndex(qstnnaireId, $event)"
            >
              <!-- <b-list-group-item
            v-for="(item, index) in list"
            :key="index"
            tag="li"
          >
            <strong> {{ item }}</strong>
          </b-list-group-item> -->

              <li
                v-for="(item, index) in questions"
                :key="index"
                class="d-flex justify-content-between p-50 border rounded"
                :id="item._id"
                style="margin-bottom: 2px"
              >
                <span class="text-primary"
                  ><b
                    ><span class="mr-1">{{ item.order }}.</span>
                    {{ item.title.substring(0, 100) }}</b
                  >
                </span>

                <div>
                  <span
                    v-if="item.is_published == 1"
                    style="font-style: italic; margin-left: 5px"
                    ><feather-icon
                      icon="CheckCircleIcon"
                      class="text-success mr-1"
                  /></span>

                  <!-- <b-button
                    variant="flat-primary"
                    class="btn-icon"
                    @click="editQs(item._id)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button> -->
                  <b-button
                    variant="flat-info"
                    class="btn-icon"
                    @click="handleEditClick(item._id)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    variant="flat-primary"
                    class="btn-icon"
                    @click="handleEditClick(item._id)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button
                    variant="flat-danger"
                    class="btn-icon"
                    @click="openDeleteModal(item._id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </li>
            </draggable>
          </div>
        </div>
      </b-overlay>

      <b-sidebar
        id="add-qs"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="add_qs"
      >
        <validation-observer
          ref="create-new-system-group"
          #default="{ invalid }"
        >
          <b-form class="mt-2" @submit.prevent="addQuestion()">
            <div class="col-12 p-2">
              <b-form-group>
                <label for="">Title</label>
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required|min:3"
                >
                  <input
                    type="text"
                    placeholder="Title"
                    class="form-control"
                    v-model="title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="">Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  class="form-control"
                  v-model="description"
                ></textarea>
              </b-form-group>
              <b-form-group>
                <label for="">Response Type</label>
                <validation-provider
                  #default="{ errors }"
                  name="Response Type"
                  rules="required"
                >
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="responsetype"
                  >
                    <option value="1">Text box</option>
                    <option value="2">Select box</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <template v-if="responsetype == 2">
                <b-form-group>
                  <label for="">Answer</label>
                  <select name="" id="" class="form-control" v-model="answer">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </b-form-group>
              </template>

              <b-form-group>
                <b-form-checkbox v-model="mandatory" class="mb-1 mt-1">
                  Response Mandatory ?
                </b-form-checkbox>
              </b-form-group>
              <template v-if="mandatory">
                <b-form-group>
                  <b-form-checkbox v-model="condition_apply" class="mb-1 mt-1">
                    Condition Validation ?
                  </b-form-checkbox>
                </b-form-group>
                <template v-if="condition_apply">
                  <b-form-group>
                    <label for="">Questions</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Questions"
                      rules="required"
                    >
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="question"
                      >
                        <option value="">Select any</option>
                        <option
                          :value="qs._id"
                          v-for="qs in questions"
                          :key="qs._id"
                        >
                          {{ qs.title }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="">Response</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="qsresponse"
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </b-form-group>
                </template>
              </template>

              <!-- {{mandatory}} -->
              <b-form-group>
                <b-form-checkbox v-model="evidence_required" class="mb-1 mt-1">
                  Evidence Required ?
                </b-form-checkbox>
              </b-form-group>

              <b-form-group>
                <label>Risk Value</label>
                <div>
                  <div>Yes</div>
                  <select
                    name=""
                    id=""
                    class="form-control mb-1"
                    v-model="riskValueYes"
                  >
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                  </select>
                  <div>No</div>
                  <select
                    name=""
                    id=""
                    class="form-control mb-1"
                    v-model="riskValueNo"
                  >
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                  </select>
                  <div>Not Applicable</div>
                  <select
                    name=""
                    id=""
                    class="form-control mb-1"
                    v-model="riskValueNotApplicable"
                  >
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                  </select>
                </div>
              </b-form-group>

              <button
                class="btn btn-primary mr-1"
                type="submit"
                :disabled="
                  !$store.state.auth.permissions.includes(
                    'tpr_question-create'
                  ) || invalid
                "
              >
                Add
              </button>
              <button class="btn btn-secondary" @click="closeQuestion">
                Cancel
              </button>
            </div>
          </b-form>
        </validation-observer>
      </b-sidebar>
      <b-sidebar
        id="edit-qs"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="edit_qs"
      >
        <div class="col-12 p-2">
          <label for="">Title</label>
          <input
            type="text"
            placeholder="Title"
            class="form-control"
            v-model="title"
          />
          <label for="">Description</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            class="form-control"
            v-model="description"
          ></textarea>
          <label for="">Response Type</label>
          <select name="" id="" class="form-control" v-model="responsetype">
            <option value="1">Text box</option>
            <option value="2">Select box</option>
          </select>
          <div v-if="responsetype == 2">
            <label for="">Answer</label>
            <select name="" id="" class="form-control" v-model="answer">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <b-form-checkbox v-model="mandatory" class="mb-1 mt-1">
            Response Mandatory ?
          </b-form-checkbox>
          <div v-if="mandatory">
            <!-- Any conditions applied ? <br /> -->
            <b-form-checkbox v-model="condition_apply" class="mb-1 mt-1">
              Condition Validation ?
            </b-form-checkbox>
            <div v-if="condition_apply">
              <label for="">Questions</label>
              <select name="" id="" class="form-control" v-model="question">
                <option value="">Select any</option>
                <option
                  :value="qs._id"
                  v-for="qs in questionslist"
                  :key="qs._id"
                >
                  {{ qs.title }}
                </option>
              </select>
              <label for="">Response</label>
              <select name="" id="" class="form-control" v-model="qsresponse">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <!-- {{qstn}} -->

          <!-- {{mandatory}} -->

          <b-form-checkbox v-model="evidence_required" class="mb-1 mt-1">
            Evidence Required ?
          </b-form-checkbox>
          <label>Risk Value</label>
          <div>
            <div>Yes</div>
            <select
              name=""
              id=""
              class="form-control mb-1"
              v-model="riskValueYes"
            >
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
            <div>No</div>
            <select
              name=""
              id=""
              class="form-control mb-1"
              v-model="riskValueNo"
            >
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
            <div>Not Applicable</div>
            <select
              name=""
              id=""
              class="form-control mb-1"
              v-model="riskValueNotApplicable"
            >
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
          </div>
          <button
            class="btn btn-primary mr-1"
            @click="updateQs(qId)"
            :disabled="
              !this.$store.state.auth.permissions.includes(
                'tpr_question-update'
              )
            "
          >
            Update
          </button>
          <button class="btn btn-secondary" @click="closeEdit">Cancel</button>
        </div>
      </b-sidebar>
      <b-modal
        ref="modal-publish"
        title="Are you sure to publish Questionnaire?"
        hide-footer
      >
        <button
          class="btn btn-primary mr-1"
          @click="publishQuestionnaire(qstnnaireId)"
        >
          Publish
        </button>
        <button class="btn btn-secondary" @click="closePublishModal">
          Cancel
        </button>
      </b-modal>
      <b-modal
        ref="modal-delete"
        title="Are you sure to delete question?"
        hide-footer
      >
        <button
          class="btn btn-danger mr-1"
          @click="deleteQs(questId)"
          :disabled="
            !this.$store.state.auth.permissions.includes('tpr_question-delete')
          "
        >
          delete
        </button>
        <button class="btn btn-secondary" @click="closeDeleteModal">
          Cancel
        </button>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BSidebar,
  BFormCheckbox,
  BListGroupItem,
  BButton,
  BModal,
  BFormFile,
  BOverlay,
  BForm,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import ResponseMixins from "../../mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import QuestionCreateSidebar from "./QuestionCreateSidebar.vue";
import QuestionUpdateSidebar from "./QuestionUpdateSidebar.vue";
import SidebarTemplate from '../../components/SidebarTemplate.vue';

export default {
  mixins: [ResponseMixins],
  components: {
    BFormRadioGroup,
    BFormGroup,
    BSidebar,
    BFormCheckbox,
    BListGroupItem,
    draggable,
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm,
    QuestionCreateSidebar,
    QuestionUpdateSidebar,
    SidebarTemplate,
  },
  data() {
    return {
      selectedQuestionId: null,
      selected: "textarea",
      options: [
        { text: "Text Area", value: "textarea" },
        { text: "Checkbox", value: "checkbox" },
        { text: "Radio Button", value: "radiobutton" },
      ],
      add_qs: false,
      evidence_required: false,
      mandatory: false,
      list: ["question 1", "question 2", "question 3", "question 4"],
      title: "",
      description: "",
      qstnnaireId: "",
      questions: [],
      qstionnardetails: [],
      question: "",
      qsresponse: "",
      edit_qs: false,
      qstn: [],
      qId: "",
      condition_apply: false,
      questionslist: [],
      answer: "",
      responsetype: "",
      questId: "",
      riskValueYes: null,
      riskValueNo: null,
      riskValueNotApplicable: null,
      selectedId: null,
      selectedFile: null,
      showOverlay: false,
    };
  },
  watch: {
    $route(to, from) {
      this.qstnnaireId = this.$route.params.id;
      console.log(this.qstnnaireId);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.qstnnaireId = this.$route.params.id;
      this.getQuestions(this.qstnnaireId);
    },
    handleDownloadButtonClick() {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_ONECSF_API_URL}/tpr_questionnaire/import-csv/sample-download`,
      };
      this.$http(options)
        .then((res) => {
          var blob = new Blob([res.data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, "sample.csv");
          } else {
            var a = window.document.createElement("a");

            a.href = window.URL.createObjectURL(blob, {
              type: "text/plain",
            });
            a.download = "sample.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((err) => console.log(err));
    },
    handleFileUploadSubmit() {
      this.showOverlay = true;
      const formData = new FormData();
      formData.append("tpr_questionnaire_list_csv", this.selectedFile);
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questionnaire/${this.qstnnaireId}/import-csv`,
      };
      this.$http(options)
        .then((res) => {
          this.handleResponse(res);
          this.getQuestions(this.qstnnaireId);
          this.hideImportModal();
        })
        .catch((err) => {
          if (
            err.data &&
            err.data.errors &&
            err.data.errors != null &&
            err.data.errors != []
          ) {
            this.showToast(
              "Error: The uploaded file contains invalid data. Please upload a valid file.",
              "danger"
            );
          } else {
            this.handleError(err);
          }
          this.selectedFile = null;
          this.hideImportModal();
          console.log("Error:", err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleEditClick(questionId) {
      this.selectedQuestionId = questionId;
      this.openQuestionUpdateSidebar();
    },

    handleImportClick() {
      this.selectedFile = null;
      this.showImportModal();
    },

    openQuestionAddSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-add");
    },

    closeQuestionAddSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-add");
    },

    openQuestionUpdateSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-update");
    },

    closeQuestionUpdateSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-update");
    },

    showImportModal() {
      this.$refs["modal-import"].show();
    },
    hideImportModal() {
      this.$refs["modal-import"].hide();
    },
    openDeleteModal(id) {
      this.questId = id;
      this.$refs["modal-delete"].show();
    },
    closeDeleteModal() {
      this.$refs["modal-delete"].hide();
    },
    newIndex: function (id, evt) {
      // alert(evt.newIndex);  // element's new index within new parent

      const data = {
        order: evt.newIndex + 1,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questions/update_order/${evt.item.id}`,
      };
      this.$http(options)
        .then((res) => {
          this.getQuestions(this.qstnnaireId);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    publishModal() {
      this.$refs["modal-publish"].show();
    },
    closePublishModal() {
      this.$refs["modal-publish"].hide();
    },
    openQuestion() {
      this.add_qs = true;
      this.title = "";
      this.description = "";
      this.mandatory = false;
      this.evidence_required = false;
      this.condition_apply = false;
      (this.question = ""), (this.qsresponse = "");
    },
    closeQuestion() {
      this.add_qs = false;
      this.title = "";
      this.description = "";
      this.mandatory = false;
      this.evidence_required = false;
      (this.question = ""), (this.qsresponse = "");
    },
    closeEdit() {
      this.edit_qs = false;
    },
    getQuestions(id) {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questions/list/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.questions = res.data.data.questions;
          this.qstionnardetails = res.data.data.questionnaire;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    addQuestion() {
      const bodyFormData = new FormData();
      bodyFormData.append("title", this.title);
      bodyFormData.append("description", this.description);
      bodyFormData.append("tpr_questionnaire_id", this.qstnnaireId);
      bodyFormData.append("response_mandatory", this.mandatory ? 1 : "");
      bodyFormData.append("evidence_rqd", this.evidence_required ? 1 : "");
      bodyFormData.append(
        "tpr_question_id",
        this.condition_apply ? this.question : ""
      );
      bodyFormData.append(
        "question_response",
        this.condition_apply ? this.qsresponse : ""
      );
      bodyFormData.append("answer", this.answer == "Yes" ? 1 : 0);
      bodyFormData.append("any_condition", this.condition_apply ? 1 : "");
      bodyFormData.append("response_type", this.responsetype);
      bodyFormData.append("risk_values[no]", this.riskValueYes);
      bodyFormData.append("risk_values[yes]", this.riskValueNo);
      bodyFormData.append(
        "risk_values[not_applicable]",
        this.riskValueNotApplicable
      );
      // const data = {
      //   title: this.title,
      //   description: this.description,
      //   tpr_questionnaire_id: this.qstnnaireId,
      //   response_mandatory: this.mandatory ? 1 : "",
      //   evidence_rqd: this.evidence_required ? 1 : "",
      //   tpr_question_id: this.condition_apply ? this.question : "",
      //   question_response: this.condition_apply ? this.qsresponse : "",
      //   answer: this.answer == "Yes" ? 1 : 0,
      //   any_condition: this.condition_apply ? 1 : "",
      //   response_type: this.responsetype,
      //   risk_values[no]: this.riskValueYes,
      //   risk_values[yes]:this.riskValueNo,
      //   risk_values[not_applicable]:this.riskValueNotApplicable,
      // };
      const options = {
        method: "POST",
        data: bodyFormData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questions`,
      };
      this.$http(options)
        .then((res) => {
          this.resetAddqs();
          this.closeQuestion();
          this.getQuestions(this.qstnnaireId);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetAddqs() {
      // tpr_questionnaire_id:this.qstnnaireId,
      // response_mandatory:this.mandatory? 1 :'',
      // evidence_rqd:this.evidence_required ? 1 : '',
      // tpr_question_id:this.question,
      // question_response:this.qsresponse == 'Yes' ? 1 : 0,
      this.title = "";
      this.description = "";
    },
    editQs(id) {
      this.qId = id;
      this.edit_qs = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questions/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.qstn = res.data.data.question;
          this.title = this.qstn.title;
          this.description = this.qstn.description;
          this.mandatory = this.qstn.response_mandatory == 1 ? true : false;
          this.evidence_required = this.qstn.evidence_rqd == 1 ? true : false;
          this.question = this.qstn.tpr_question_id;
          this.qsresponse = this.qstn.question_response;
          this.condition_apply =
            this.qstn.tpr_question_id == null ? false : true;
          (this.questionslist = res.data.data.questions),
            (this.answer = this.qstn.answer == 1 ? "Yes" : "No");
          this.responsetype = this.qstn.response_type;
          this.riskValueYes = this.qstn.risk_values.yes;
          this.riskValueNo = this.qstn.risk_values.no;
          this.riskValueNotApplicable = this.qstn.risk_values.not_applicable;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteQs(id) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questions/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.getQuestions(this.qstnnaireId);
          this.$refs["modal-delete"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateQs(id) {
      const bodyFormData = new FormData();
      bodyFormData.append("title", this.title);
      bodyFormData.append("description", this.description);
      bodyFormData.append("tpr_questionnaire_id", this.qstnnaireId);
      bodyFormData.append("response_mandatory", this.mandatory ? 1 : "");
      bodyFormData.append("evidence_rqd", this.evidence_required ? 1 : "");
      bodyFormData.append(
        "tpr_question_id",
        this.condition_apply ? this.question : ""
      );
      bodyFormData.append(
        "question_response",
        this.condition_apply ? this.qsresponse : ""
      );
      bodyFormData.append("answer", this.answer == "Yes" ? 1 : 0);
      bodyFormData.append("any_condition", this.condition_apply ? 1 : "");
      bodyFormData.append("response_type", this.responsetype);
      bodyFormData.append("risk_values[no]", this.riskValueYes);
      bodyFormData.append("risk_values[yes]", this.riskValueNo);
      bodyFormData.append(
        "risk_values[not_applicable]",
        this.riskValueNotApplicable
      );
      // const data = {
      //   title: this.title,
      //   description: this.description,
      //   tpr_questionnaire_id: this.qstnnaireId,
      //   response_mandatory: this.mandatory ? 1 : "",
      //   evidence_rqd: this.evidence_required ? 1 : "",
      //   tpr_question_id: this.condition_apply ? this.question : "",
      //   question_response: this.condition_apply ? this.qsresponse : "",
      //   answer: this.answer == "Yes" ? 1 : 0,
      //   any_condition: this.condition_apply ? 1 : "",
      //   response_type: this.responsetype,
      // };
      const options = {
        method: "PUT",
        data: bodyFormData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/tpr_questions/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.edit_qs = false;
          this.getQuestions(this.qstnnaireId);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    publishQuestionnaire(id) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/tpr_questionnaire/${id}/publish`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-publish"].hide();
          this.getQuestions(this.qstnnaireId);
          // this.getQuestionnair();
          this.$router.go();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
option:disabled {
  background: #ccc !important;
}
</style>
